<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="d-flex justify-content-center ml-auto mr-auto">
        <card class="card-login">
          <div slot="header">
            <div class="logo-container">
              <img src="/img/logo.png" alt="logo" />
            </div>
          </div>

          <div>
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <fg-input
                type="email"
                :error="failed ? 'The Email field is required' : null"
                :hasSuccess="passed"
                class="form-control-lg"
                placeholder="Email"
                addon-left-icon="now-ui-icons ui-1_email-85"
                v-model="email"
              ></fg-input>
            </ValidationProvider>

            <ValidationProvider name="password" rules="required|min:5" v-slot="{ passed, failed }">
              <fg-input
                type="password"
                :error="failed ? 'The Password field is required' : null"
                :hasSuccess="passed"
                class="form-control-lg"
                placeholder="Password"
                addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                v-model="password"
              ></fg-input>
            </ValidationProvider>
          </div>
          <div class="d-flex justify-content-center">
            <n-button :disabled="loading" class="btn-submit" native-type="submit" type="info">SUBMIT</n-button>
          </div>
        </card>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            message: error.response.data.error.message,
            timeout: 30000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    },
  },
};
</script>
<style scoped>
.btn-submit {
  margin-top: 50px;
}
</style>
