<template>
  <category-form :submit="submit" :formTitle="'Category Detail'" role="update"></category-form>
</template>
<script>
import { mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import CategoryForm from "./CategoryForm";
import { userRolesCheck } from "@/util/userRoles";
import Swal from "sweetalert2";

export default {
  components: {
    "category-form": CategoryForm
  },
  created() {
    userRolesCheck("update", "category_management");
  },
  methods: {
    ...mapActions("categories", ["updateCategory"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.updateCategory(this.$route.params.category_id)
        .then((response) => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Update category success",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "Category List" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Update category failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    }
  },

}
</script>
