<template>
  <div class="row">
    <div class="col-12">
      <card no-footer-line>
        <h4 slot="header" class="card-title">Categories</h4>
        <div class="row">
          <div class="col-sm-12">
            <router-link
              :to="{ name: 'Create Category' }"
              :disabled="!hasRole('create')"
              class="btn pull-right btn-border"
            >Add Category</router-link
            >
          </div>
        </div>
        <div>
          <el-table
            stripe
            v-loading="loading"
            style="width: 100%"
            :data="tableData"
          >
            <el-table-column min-width="200" label="Name">
              <template slot-scope="props">
                <span
                  @click="handleRedirect(props.$index, props.row)"
                  style="color: #52c1e9; cursor: pointer">
                  {{ props.row.name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column min-width="200" label="Language">
              <template slot-scope="props">
                {{ props.row.languages }}
              </template>
            </el-table-column>
            <el-table-column :min-width="70" fixed="right" label="Actions">
              <div slot-scope="props" class="table-actions">
                <el-tooltip content="Edit" :open-delay="300" placement="top">
                  <n-button
                    @click.native="handleEdit(props.$index, props.row)"
                    class="edit"
                    type="success"
                    size="sm"
                    icon
                    :disabled="!hasRole('update')"
                  >
                    <i class="now-ui-icons ui-2_settings-90"></i>
                  </n-button>
                </el-tooltip>
                <el-tooltip content="Delete" :open-delay="300" placement="top">
                  <n-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove"
                    type="danger"
                    size="sm"
                    icon
                    :disabled="!hasRole('delete')"
                  >
                    <i class="now-ui-icons ui-1_simple-remove"></i>
                  </n-button>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div>
            <p class="card-category">
              Showing {{ categories.meta.pagination.current_page }} to
              {{ categories.meta.pagination.total_pages }} of
              {{ categories.meta.pagination.total }} entries
            </p>
          </div>
          <n-pagination
            class="pagination-no-border"
            v-model="categories.meta.pagination.current_page"
            :per-page="categories.meta.pagination.per_page"
            :total="categories.meta.pagination.total"
            @input="updatePage"
          >
          </n-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { Table, TableColumn, Loading, MessageBox } from "element-ui";
import { Pagination as NPagination } from "src/components";
import {findUserRole, userRolesCheck} from "@/util/userRoles";
import Helper from "@/util/helper";

export default {
  components: {
    NPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created: function() {
    userRolesCheck("read", "category_management");
    this.page =
      typeof this.$route.query.page === "undefined"
        ? this.page
        : this.$route.query.page;
    this.getCategories({ page: this.page, limit: this.limit })
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("categories", ["tableData"]),
    ...mapState("categories", ["categories"]),
  },
  data() {
    return {
      loading: true,
      page: 1,
      limit: 30,
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 100
        },
        {
          prop: "lang",
          label: "Language",
          minWidth: 200
        }
      ]
    };
  },
  methods: {
    ...mapActions("categories", ["getCategories", "deleteCategory"]),
    hasRole(role) {
      return findUserRole(role, "category_management");
    },
    updatePage(page) {
      if (page !== this.page) {
        this.page = page;

        this.$router.replace({
          name: "Category List",
          query: { page: this.page },
        });

        this.loading = true;
        this.getCategories({ page: this.page, limit: this.limit })
          .then(response => {
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
          });
      }
    },
    handleRedirect(index, category) {
      this.$router.push({
        name: "Category - Voucher List",
        params: {
          id: category.category_id,
          slug: Helper.convertToSlug(category.name)
        }
      });
    },
    handleEdit(index, category) {
      this.$router.push({
        name: "Edit Category",
        params: {
          category_id: category.category_id,
          slug: Helper.convertToSlug(category.name)
        }
      });
    },
    handleDelete(index, category) {
      MessageBox.confirm(
        `This will permanently delete category "${category.name}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true,
          });
          this.deleteCategory(category)
            .then((response) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete category success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              loadingInstance.close();
              this.$notify({
                message:
                  "Delete category failed.\nSome vouchers already used this category.",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
<style></style>
